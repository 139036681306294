:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

html {

  min-height: -webkit-fill-available;
  min-width: -webkit-fill-available;
}
body {
  margin: 0;
  display: flex;
  place-items: center;
  -webkit-user-select: none;
  user-select: none;
  background-color:var(--arc-palette-background,#fafafa);
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

#app {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vanilla:hover {
  filter: drop-shadow(0 0 2em #3178c6aa);
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  display:flex;
  flex-direction: column;
  justify-content: center;
}
#app {
  position:absolute;
  top:0;
}
#menu {
  opacity:0.0;
  position:fixed;
  top:0;
  right:0;
  width:auto;
  background-color:black;
  color:white;
  padding:8px;
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  gap:8px;
  cursor:default;
  .title {
    font-weight:bold;
    text-align:right;
  }
  .contents {
    display:flex;
    flex-direction: column;
    gap:8px;
  }
  button {
    display:block;
  }

}
#menu:hover {
  opacity:1.0;
}
#menu:not(:hover) .contents {
  display:none;
}